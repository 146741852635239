  .hero--section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 133.333px 85.333px 133.333px;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    background-color: var(--bg-shade);
  }
  
  .hero--section--content--box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }
  
  .hero--section--content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .hero--section--welcome {
    color: var(--heading-color);
    font-size: clamp(16px, 3vw, 21px); /* Scales between 16px and 21px */
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    display: flex;
    align-items: flex-start;
    place-items: center;
  }
  
  .hero--section--title {
    color: var(--heading-color);
    font-size: clamp(32px, 6vw, 74.667px) !important; /* Scales between 48px and 74.667px */
    font-weight: 700;
    line-height: 90px;
    align-self: stretch;
  }
  
  .hero--section-title--color {
    color: var(--black);
  }
  
  .hero--section-description {
    color: var(--darkblue);
    font-size: clamp(16px, 2.5vw, 24px); /* scales between 16px and 24px */
    font-weight: 400;
    line-height: 36px;
  }
  
  .hero--section--img {
    display: flex;
  }
  
  .hero--section--img > img {
    width: 50%;
    height: 50%;
    border-radius: 15px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    object-fit: cover;
    margin: 0 auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .hero--section--img img:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.15);
  }

  @media only screen and (max-width: 1800px) {
  .hero--section--title {
    font-size: 68px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 1200px) {
  @media only screen and (max-width: 1200px) {
    .hero--section {
      display: flex;
      flex-direction: column-reverse;
      padding: 133.333px 16px 70px;
    }
  
    .hero--section--title,
    .hero--section--welcome,
    .hero--section-description,
    .footer--content {
      text-align: center;
    }
  
    .hero--section--content--box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  
    .hero--section--content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  
    .hero--section--welcome {
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .hero--section--title--color {
      font-size: 12px;
    }
  }
}


  
  