.about--section {
  display: grid;
  padding: 200px 80px;
  margin-top: 50px;
  margin-bottom: 100px;
  align-items: center;
  gap: 60px;
  grid-template-columns: repeat(2, 1fr);
}

.about--section--img .carousel .slide img {
  width: 105%;
  height: auto;
  border-radius: 15px !important;
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  margin: 0 auto;
  overflow: hidden;
}

.carousel .slide {
  opacity: 0.5;
  transform: scale(0.85);
  transition: transform 0.5s ease, opacity 0.5s ease;
  background-color: transparent !important;
}

.carousel .slide.selected {
  opacity: 1;
  transform: scale(1);
  background-color: transparent !important;
}

.carousel.carousel-slider, .carousel-root {
  background-color: transparent !important;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  opacity: 0.5;
}

.carousel .control-arrow:hover, .carousel.carousel-slider .control-arrow:hover {
  opacity: 1;
}

.carousel .control-dots {
  display: none;
}

.about--section--img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  overflow: hidden;
  background-color: transparent !important;
}

.about--section--heading {
  text-align: center;
  padding: 1%;
  font-size: 3rem;
}

.about--section--description {
  font-size: 1.2rem !important;
  line-height: 1.5;
  text-align: justify;
  text-align-last: center;
}

@media only screen and (max-width: 1200px) {
  .about--section {
    grid-template-columns: 1fr; 
    padding: 100px 20px; 
    gap: 10px; 
  }

  .about--section--img {
    width: 100%;
    height: auto;
    margin-bottom: 20px; 
  }

  .about--section--heading {
    font-size: 2rem;
  }

  .about--section--description {
    font-size: 1.3rem !important;
  }
}
