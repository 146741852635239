.offerings--section {
  margin-top: 80px; /* This will shift the section downward */
}

.offerings--section--heading {
  text-align: center;
  padding: 10px 0px 20px;
  margin: 10px;
  font-size: 40px;
}

.offerings--section--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
}

.offerings--section--card {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  border-radius: 10.6px;
  background: var(--bg-shade);
  min-height: 250px;
  color: inherit;
  text-decoration: none;
  max-width: 280px; 
  box-sizing: border-box;
  position: relative;
}

.offerings--section--img {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13.3px;
  width: 100%;
}

.offerings--section--img img {
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 10.6px;
  object-fit: cover;
}

.offerings--section--card--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 32px;
  gap: 5px;
  align-self: stretch;
  flex-grow: 1;
}

.offerings--section--title {
  color: var(--heading-color);
  font-size: 32px;
  font-weight: 700;
  line-height: 45px;
}

.offerings--section--description {
  color: var(--black);
  font-size: 21.3px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.offerings--section--title a,
.offerings--section--description a {
  color: inherit;
  text-decoration: none;
}

.offerings--section--title a:hover,
.offerings--section--description a:hover {
  color: var(--primary);
}

.image-flip-container {
  position: relative;
  width: 100%;
  max-width: 210px; 
  height: 350px; 
  perspective: 1000px; 
}

.image-flip-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s ease;
  cursor: pointer;
}

.image-flip-container:hover .image-flip-inner {
  transform: rotateY(180deg);
}

.image-flip-front,
.image-flip-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; 
  top: 0;
  left: 0;
  border-radius: 10.6px; 
  overflow: hidden;
}

.image-flip-front img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10.6px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
}

.image-flip-back {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--bg-shade);
  padding: 16px;
  transform: rotateY(180deg); 
}

.image-flip-back h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.image-flip-back ul {
  list-style: none; 
  padding: 0;
  margin: 0;
  width: 100%; 
}

.image-flip-back li {
  position: relative;
  padding-left: 25px; 
  margin-bottom: 2px; 
  font-size: 16px;
  color: var(--text-secondary); 
  line-height: 1.6; 
}

.image-flip-back li::before {
  content: "○"; 
  position: absolute;
  left: 0;
  font-size: 18px; 
  color: var(--primary); 
  transform: scale(1);
}

@media only screen and (max-width: 1200px) {
  .offerings--section--container,
  .offerings--section {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .offerings--section--card {
    width: 100%;
    max-width: none;
    gap: 20px;
  }

  .offerings--section--card--content {
    padding: 24px;
    gap: 20px; 
  }
  .offerings--section--heading {
    text-align: center;
    padding: 0;
    margin-left: 0;
  }
  .offerings--section--description {
    text-align: center;
  }
}
