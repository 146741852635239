.contact--section {
    display: flex;
    padding: 150px 85px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
  }
  
  .contact--form--container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(5, auto);
    width: 40%;
    row-gap: 32px;
  }
  
  .container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 32px;
    row-gap: 32px;
  }
  
  .contact--label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10.6px;
    flex: 1 0 0;
    width: 100%;
  }
  
  .contact--input {
    display: flex;
    font-family: "Roboto";
    padding: 16px;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1.333px solid var(--primary);
    background: var(--white);
  }

  .contact--header {
    font-size: 3rem;
    margin-bottom: 10px;
  }

  .contact--subheader {
    font-size: 2rem;
  }
  
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Some browsers will not display the caret when using calc, so we put the fallback first */
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
      white no-repeat 98.5% !important; /* !important used for overriding all other customizations */
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
      white no-repeat calc(100% - 20px); /* Better placement regardless of input width */
  }
  
  /* For IE */
  select::-ms-expand {
    display: none;
  }
  
  .checkbox--label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10.6px;
    flex: 1 0 0;
    justify-content: flex-start;
  }
  
  input[type="checkbox"] {
    width: 21px;
    height: 21px;
    border: 0.15em solid var(--heading-color);
    border-radius: 0.15em;
    transform: translatey(-0.075em);
  }
  
  .btn {
    font-family: "Roboto";
    display: inline-block;
    padding: 14px 32px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    transition: background-color 0.5s;
    -webkit-transition: background-color 0.5s;
    -moz-transition: background-color 0.5s;
    -ms-transition: background-color 0.5s;
    -o-transition: background-color 0.5s;
  }
  
  .btn-primary {
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--primary);
  }
  
  .btn-primary:hover {
    color: var(--primary);
    background-color: var(--white);
  }

  @media only screen and (max-width: 1200px) {
    .contact--form--container {
      width: 100%;
    }
    .container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .contact-btn-container {
      display: flex; 
      justify-content: center; 
    }
  }
  
  