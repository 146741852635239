.calendar--section--heading {
    color: var(--heading-color);
    font-size: 48px;
    font-weight: 700;
    line-height: 77px;
    text-align: center;
}

.calendar-container {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Garamond', serif;
}
  
.calendar-event-wrapper {
    display: flex;
    justify-content: center;
    gap: 40px;
    max-width: 1200px;
    width: 100%;
}
  
.react-calendar {
    flex: 2;
    max-width: 100%;
    border: none;
    border-radius: 8px;
    background-color: #ffffff;
    color: #37474f;
}
  
.react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
  
.react-calendar__navigation button {
    background: none;
    border: none;
    color: #37474f;
    font-size: 24px;
    font-weight: bold;
    padding: 5px;
    cursor: pointer;
}
  
.react-calendar__navigation button:disabled {
    color: #ccc;
    cursor: default;
}
  
.react-calendar__navigation__label {
    font-family: "garamond", serif;
    font-size: 24px;
    font-weight: 600;
    color: #37474f;
}
  
.react-calendar__month-view__weekdays {
    font-family: "garamond", serif;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #37474f;
    margin-bottom: 10px;
}
  
.react-calendar__month-view__weekdays__weekday abbr {
    color: #37474f;
    border-bottom: none !important;
    outline: none !important;
    text-decoration: none !important;
    box-shadow: none !important;
}

.react-calendar__month-view__days__day{
    font-family: "garamond", serif;
}
  
.react-calendar__tile {
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    transition: background 0.3s ease, color 0.3s ease;
    font-size: 16px;
    color: #37474f;
}
  
.react-calendar__tile--now {
    background-color: #eef3ff;
    color: var(--primary);
    border-radius: 4px;
}
  
.react-calendar__tile--active {
    background-color: var(--primary);
    color: #ffffff;
    border-radius: 4px;
}
  
.react-calendar__tile:enabled:hover {
    background-color: var(--primary-hover);
    color: #ffffff;
    border-radius: 4px;
}

.react-calendar__tile:enabled:focus {
    background-color: #96a6fb;
    color: #ffffff;
    border-radius: 4px;
}
  
.react-calendar__month-view__days__day--weekend {
    color: #37474f !important;
}
  
.event-list {
    flex: 1;
    max-width: 300px;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    color: #37474f;
    max-height: 500px;
    overflow-y: auto;
}
  
.event-list h3 {
    font-size: 18px;
    color: var(--primary);
    margin-bottom: 20px;
    font-weight: 600;
}
  
.event-list ul {
    list-style-type: none;
    padding: 0;
}
  
.event-list li {
    margin-bottom: 15px;
    padding: 15px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
} 

.event-list li strong {
    color: var(--primary);
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
} 

.event-details {
    margin-top: 10px;
    color: #555;
    font-size: 14px;
}
  
.react-calendar__tile ul {
    list-style-type: none;
    padding-left: 0;
    margin: 5px 0 0 0;
}
  
.react-calendar__tile ul li {
    font-size: 8px;
    margin: 2px 0;
    color: #37474f;
}

.event-list--btn {
    font-family: "Roboto";
    display: inline-block;
    margin-top: 20px;
    padding: 2px 80px;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--primary);
}
  
.event-list--btn:hover {
    color: var(--primary);
    background-color: var(--white);
}

.react-calendar__navigation button.react-calendar__navigation__prev2-button, .react-calendar__navigation button.react-calendar__navigation__next2-button {
    display: none;
}

.calendar--section--button {
    display: flex;
    justify-content: center;
  }
  
  .calendar--section--button .btn {
    padding: 12px 50px; 
    font-size: 16px;
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .calendar--section--button .btn:hover {
    transform: scale(1.05);
  }
  
@media (max-width: 768px) {
    .calendar-event-wrapper {
        flex-direction: column;
        align-items: center;
    }
  
    .react-calendar,
    .event-list {
        max-width: 100%;
        margin: 0 auto;
    }
  
    .event-list {
        margin-top: 20px;
        text-align: center;
        align-content: center;
    }
}
