.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; 
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  max-height: 80vh; 
  overflow-y: auto; 
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-tabs {
  display: flex;
  border-bottom: 1px solid #ddd;
}

.modal-tab {
  background: transparent;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.modal-tab.active {
  border-bottom: 2px solid #000;
  font-weight: bold;
}

.modal-body {
  padding-top: 20px;
  max-height: calc(80vh - 100px); 
  overflow-y: auto; 
}
