.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 85.333px;
  background: var(--white);
  box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.navbar--img {
  width: 7.5%;
  height: auto;
  border-radius: 50%;
}

.navbar--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}

.navbar--items ul > li > a {
  text-decoration: none;
  color: var(--black);
}

.navbar--content {
  color: var(--primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-wrap: nowrap;
}

.navbar--active-content {
  color: var(--primary-hover) !important;
}

.navbar--btn {
  font-family: "Roboto";
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-wrap: nowrap;
  margin-left: 30px;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.navbar--btn:hover {
color: var(--primary);
background-color: var(--white);
}

/* Hamburger menu */
.nav__hamburger {
  all: unset; 
  display: none;
  width: 1.875rem;
  height: 1.313rem;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 25px;
  right: 25px;
}

.nav__hamburger__line {
  display: block;
  height: 0.188rem;
  width: 100%;
  background-color: #000000;
  border-radius: 0.625rem;
  transition: all ease-in-out 0.2s;
}

@media screen and (max-width: 1200px) {
  .nav__hamburger {
      display: flex;
      z-index: 200;
  }

  img.navbar--img {
    margin-left: -50px;
    width: 50px;
    height: auto;
    border-radius: 50%;
  }

  .navbar--items {
      position: absolute;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255);
      top: -30rem;
      left: 0;
      width: 100%;
      transition: all ease-in-out 0.4s;
  }

  .navbar--items ul {
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 3rem 0 0.6rem;
      z-index: -100;
  }

  .navbar--items ul li {
      text-align: center;
  }

  .navbar--items.active {
      top: 0rem;
  }

  .nav__hamburger.active :nth-child(1) {
      transform: rotate(45deg) translate(0.45rem, 0.1875rem);
  }

  .nav__hamburger.active :nth-child(2) {
      opacity: 0;
  }

  .nav__hamburger.active :nth-child(3) {
      transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
  }
}
