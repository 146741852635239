/* Global CSS */

body {
  margin: 0;
  font-family: "garamond", serif;
  -webkit-font-smoothing: "garamond", serif;
  -moz-osx-font-smoothing: "garamond", serif;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
:root {
  --primary: #7992FF;
  --primary-hover: #6578E5;
  --secondary: #A6EADA;
  --black: #000000;
  --white: #ffffff;
}
