.discounts--section {
    display: flex;
    padding: 112px 85px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  
  .discounts--container-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .discounts--container {
    display: flex;
    justify-content: center; 
    align-items: center; 
    width: 100%; 
  }

  .discount-heading {
    text-align: center;
    margin: 0 auto; 
    width: 100%; 
    font-size: 40px; 
    font-weight: bold; 
  }
  
  .discounts--section--container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;
  }
  
  .discounts--section--img {
    border-radius: 8px;
    width: 100%;
  }
  
  .discounts--section--img > img {
    width: 100%;
    border-radius: 30px;
  }
  
  .discounts--section--card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    background: #fff;
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-radius: 30px;
    max-width: 280px; 
    box-sizing: border-box;
    min-height: 500px; 
    position: relative; 
  }
  
  .discounts--section--card--content {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    flex-grow: 1; 
  }
  
  .discounts--section--title {
    color: var(--heading-color);
  }
  
  .discounts--link {
    text-decoration: none;
    display: flex;
    gap: 16px;
    border-bottom: 1.33px solid var(--primary);
    padding-bottom: 10.6px;
    font-weight: 600;
    position: absolute; 
    bottom: 16px; 
  }
  
  .discounts--section--card:hover path {
    stroke: #006b6a;
  }

  @media only screen and (max-width: 1200px) {
    .discounts--section--container,
    .discounts--container-box {
      flex-direction: column;
    }
    .discounts--container-box {
      gap: 30px;
    }
  }
  
  