.footer--container {
  display: flex;
  padding: 106.667px 85.333px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--bg-shade);
  align-items: center;
  align-self: stretch;
}

.footer--link--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.footer--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}

.footer--items ul > li > a {
  text-decoration: none;
  text-wrap: nowrap;
}

.text-md {
  color: black;
}

.footer--social--icon > ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
}

.divider {
  margin: 10px 0;
  margin-bottom: 0;
  height: 1.333px;
  width: 100%;
  background: rgba(40, 41, 56, 0.55);
}

@media only screen and (max-width: 1200px) {
  .footer--link--container,
  .footer--items > ul,
  .footer--content--container {
    flex-direction: column;
    align-items: center;
  }
  
  .footer--social--icon > ul,
  .footer--items > ul {
    padding: 0;
    align-items: center;
  }
  
  .footer--img--container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    margin-bottom: 20px; 
  }

  .footer--img--container img {
    display: inline-block;
    margin: 0 auto;
  }

  .divider {
    margin: 20px;
  }
}
